body {
	background-image: url("/public/background_1-2-0.png");
	background-repeat: no-repeat;
	background-size: cover;

}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
