@font-face {
	font-family: "GBA-Font";
	src: url(/public/PokemonGBA.ttf) format(truetype);
}

h1 {
	color: black;
}

h2 {
	color: black;
	font-family: "GBA-Font", sans-serif;
}

.display {
		display: flex;
		justify-content: center;
		align-items: center;
		/* border-radius: 15px;
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); */
		padding: 20px;
		width: 70%;
		height: auto;
		margin: 90px auto;	
}

@media only screen and (min-width: 768px) {
	.display {
	width: 50%;
	}
	}
	

.pokemon-container {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	margin-left: 0px;
}

.pokemon-name {
	margin-top: -10px;
	font-size: 20px;
}

.pokemon-image {
	width: 160px;
	height: auto;
	display: block;
	margin: 0 auto;
}
