.footer-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #d3d3d3;
	padding: 10px;
}

.footer-info {
	font-size: 12px;
}

.footer-info p {
	margin: 0;
}

.footer-links {
	display: flex;
	justify-content: flex-end;
}

.footer-links a {
	margin-left: 10px;
	text-decoration: none;
	color: black;
	font-size: 12px;
}
